import React, { useRef } from 'react';
import { Typography, Image, Button, Accordion, List, ListItem } from '../../components';
import { GeneralContext } from '../../App';

import { Kosten } from './styled';

import vinkje from '../../assets/icons/vink.png';
import kruisje from '../../assets/icons/kruis.png';
import pijltje from '../../assets/icons/pijltje.png';

const pakketten = [
    {
        name: 'Solo pakket',
        period: `Tijdens de\nzwangerschap`,
        answers: ['ja', 'ja', 'nee', 'ja'],
        kosten: ['148', '0'],
        color: '#ECF4F1',
    },
    {
        name: 'Solo pakket',
        period: `Tijdens de\nkraamperiode`,
        answers: ['nee', 'nee', 'ja', 'ja'],
        kosten: ['124', '0'],
        color: '#D5E4DC'
    },
    {
        name: 'Compleet pakket',
        period: `Zwangerschap\nén kraamperiode`,
        answers: ['ja', 'ja', 'ja', 'ja'],
        kosten: ['172', '0'],
        color: '#A1C2BC'
    },

]

export default function KostenTable(props) {
    const { workshopList, pageText, linkAanmelden } = props;
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    // const sizeMultiplier = Math.min(window.innerWidth / 1040, 1);
    const topOffsetTable = 90;
    const pakketOptiesWidth = 250; // px
    const pakketOptiesPadding = 30; // only on one side
    const pakketOptiesHeight = 100;
    const pakketOptiesTotalWidth = pakketOptiesPadding + pakketOptiesWidth;

    const colWidth = 140;
    const colPadding = 15;
    const colGap = 10;
    const tableWidth = pakketOptiesWidth + pakketOptiesPadding + 3*(colWidth + colGap);

    // multiplier for screen size
    let sizeMultiplier = Math.min(window.innerWidth / 1040, 1);
    if (hasPhone) {
        // 40 to account for padding
        sizeMultiplier = (window.innerWidth - 40) / tableWidth;
    } else if (hasTablet) {
        sizeMultiplier = (window.innerWidth - 150) / tableWidth;
    }

    const markSize = 35;

    const colHeight1 = topOffsetTable + 4*pakketOptiesHeight;
    const colHeight2 = 2*pakketOptiesHeight + colGap;

    const kosten1Ref = useRef(null);
    const kosten2Ref = useRef(null);
    const kosten3Ref = useRef(null);
    const kosten4Ref = useRef(null);
    const nietVerzekerdRef = useRef(null);
    const kostenRefs = [kosten1Ref, kosten2Ref, kosten3Ref, kosten4Ref];

    const mapColumns = () => {
        let leftPosition = pakketOptiesTotalWidth;

        return (
            <div>
                {pakketten.map((pakket, i) => {
                    if (i !== 0) {
                        leftPosition += colGap+colWidth;
                    }
                    return (
                        <div key={i} style={{ position: 'absolute', top: '0', left: leftPosition*sizeMultiplier, width: colWidth * sizeMultiplier }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: colHeight1 * sizeMultiplier, backgroundColor: pakket.color, padding: colPadding * sizeMultiplier, margin: `0 0 ${colGap * sizeMultiplier}px 0` }}>
                                <div style={{ minHeight: pakketOptiesPadding }}>
                                    <Typography type='caps' style={{ color: 'var(--trust)', fontSize: hasPhone ? '0.4em' : hasTablet ? '0.8em' : '0.9em', textAlign: 'center' }}>{pakket.name}</Typography>
                                    <Typography type='caps' style={{ color: 'var(--trust)', fontSize: hasPhone ? '0.2em' : hasTablet ? '0.5em' : '0.6em', textAlign: 'center', margin: '0 0 1vw 0', whiteSpace: 'pre', padding: `0 ${colPadding * sizeMultiplier}px`}}>{pakket.period}</Typography>
                                </div>
                                {pakket.answers.map((answer, j) => (
                                    <div key={j} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: pakketOptiesHeight * sizeMultiplier, margin: j < pakket.answers.length - 1 && `0 0 ${colGap * sizeMultiplier}px 0` }}>
                                        {answer === 'ja' ? 
                                            <img src={vinkje} alt='vinkje' style={{ width: `${sizeMultiplier*(markSize+10)}px`, height: `${sizeMultiplier*(markSize+10)}px`, }} /> 
                                            : 
                                            <img src={kruisje} alt='kruisje' style={{ width: `${sizeMultiplier*(markSize)}px`, height: `${sizeMultiplier*(markSize)}px` }} />}
                                    </div>
                                ))}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: colHeight2 * sizeMultiplier, backgroundColor: pakket.color }}>
                                {pakket.kosten.map((kost, j) => (
                                    <div key={j} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: pakketOptiesHeight * sizeMultiplier, margin: j < pakket.answers.length - 1 && `0 0 ${colGap * sizeMultiplier}px 0` }}>
                                        <Typography type='h2' style={{ fontSize: hasPhone && '14px'}}>€{kost},-</Typography>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <Kosten>
            <Typography type='h2' style={{ color: '#466166', fontSize: hasPhone ? '1.5em' : '2em', margin: '0 0 5vw 0'}}>{pageText[26].text}</Typography>
            <div style={{ position: 'relative', width: tableWidth * sizeMultiplier }}>
                {workshopList.map((workshop, i) => (
                    <div key={i} style={{ width: tableWidth * sizeMultiplier, height: pakketOptiesHeight * sizeMultiplier, backgroundColor: '#FDFCFA', margin: i === 0 ? `${topOffsetTable * sizeMultiplier}px 0 ${colGap * sizeMultiplier}px 0` : `0 0 ${colGap * sizeMultiplier}px 0` }}>
                        <div ref={kostenRefs[i]} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: `0 ${pakketOptiesPadding * sizeMultiplier}px 0 0`, width: pakketOptiesWidth * sizeMultiplier, textAlign: 'center'}}>
                            <Image src={workshop.image} width={pakketOptiesHeight*sizeMultiplier} height={pakketOptiesHeight*sizeMultiplier} style={{ border: 'none'}} />
                            <div style={{ textAlign: 'right', }}>
                                <Typography type='caps' style={{ color: 'var(--trust)', fontSize: hasPhone ? '0.5em' : '1.05em'}}>{workshop.name}</Typography>
                                <Typography type='caps' style={{ color: 'var(--trust)', fontSize: hasPhone ? '0.3em' : hasTablet ? '0.7em' : '0.9em', margin: '0.5vw 0 0 0'}}>{workshop.period}</Typography>
                            </div>
                        </div>
                    </div>
                ))}
                {['niet','wel'].map((m, i) => (
                    <div key={i} style={{ margin: `0 0 ${colGap * sizeMultiplier}px 0`, height: pakketOptiesHeight * sizeMultiplier, width: tableWidth * sizeMultiplier, backgroundColor: '#FDFCFA', }}>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: pakketOptiesWidth * sizeMultiplier, padding: `0 ${pakketOptiesPadding * sizeMultiplier}px 0 0`, gap: '0.5vw', backgroundColor: '#FDFCFA', textAlign: 'center', minHeight: pakketOptiesHeight * sizeMultiplier}} ref={nietVerzekerdRef}>
                            <div style={{ margin: '0 0 0 0', textAlign: 'right', }}>
                                <Typography type='caps' style={{ color: 'var(--trust)', fontSize: hasPhone ? '0.5em' : '1.05em'}}>Kosten</Typography>
                                <Typography type='caps' style={{ color: 'var(--trust)', fontSize: hasPhone ? '0.3em' : hasTablet ? '0.7em' : '0.9em', margin: '0 0 0 0', maxWidth: pakketOptiesWidth*0.8*sizeMultiplier }}>{m === 'niet' ? 'Indien niet verzekerd voor cursussen of lactatiekundige zorg' : 'Indien verzekerd voor cursussen of lactatiekundige zorg'}</Typography>
                            </div>
                    </div>
                </div>
                ))}
                {mapColumns()}
                {!hasPhone && !hasTablet && <div style={{ position: 'absolute', top: '-35px', right: '-5px', zIndex: '3',  }}>
                    <img src={pijltje} style={{ width: `${(sizeMultiplier*pakketOptiesWidth)/3.5}px` }} alt='pijltje'/>
                </div>}
                {!hasPhone && !hasTablet && <div style={{ position: 'absolute', top: '-45px', right: `-${(pakketOptiesWidth*sizeMultiplier)/3 + 50}px`, width: colWidth*sizeMultiplier, backgroundColor: '#FDFCFA', display: 'flex', borderRadius: '50%', padding: `${sizeMultiplier*colGap*2}px ${sizeMultiplier*colGap}px` }}>
                    <Typography style={{ fontSize: '0.9em', textAlign: 'center' }}>Aanbevolen, alle workshops sluiten op elkaar aan</Typography>
                </div>}
            </div>
            <Button 
                text='ZOEK HIER JOUW LACTATIEKUNDIGE'
                onClick={() => linkAanmelden()}
                style={{ width: sizeMultiplier*(tableWidth - colGap), marginLeft: 0, marginTop: hasPhone ? '5vw' : '2vw', fontSize: hasPhone && '10px' }}
            />
            <Accordion
                headertext='LEES HIER WAT JE BIJ DEELNAME ONTVANGT'
                headercolor='rgb(191,112,82)'
                align={'flex-start'}
                style={{ width: sizeMultiplier*(tableWidth - colGap), marginTop: '2vw', marginLeft: 0, fontSize: hasPhone && '14px' }}
            >
                <List>
                    <ListItem>Kennis, vaardigheden en hulpmiddelen om succesvol borstvoeding te geven aan jullie kindje;</ListItem>
                    <ListItem>Persoonlijke begeleiding door een lactatiekundige uit de buurt;</ListItem>
                    <ListItem>Kennismaking met andere koppels uit jullie omgeving die ook in verwachting zijn.</ListItem>
                </List>
                <Typography style={{ margin: '1vw 0 0.5vw 0'}}>Dit ontvang je door:</Typography>
                <List>
                    <ListItem>3 fysieke workshops van 2 uur, gegeven door een borstvoedingsexpert;</ListItem>
                    <ListItem>Toegang tot een online platform vol met video’s gerelateerd aan borstvoeding;</ListItem>
                    <ListItem>Het Goed Aangelegd cursusboek: een compleet en persoonlijk naslagwerk.</ListItem>
                </List>
            </Accordion>
        </Kosten>
    )
}