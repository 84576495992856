import React, { useRef, useState, useEffect } from 'react';

import { Veelgestelde, BlogEnPodcast, Benieuwd, Ervaringen, Medewerking } from '../../subpages';
import { Container, Educatie, Background, Samenwerken, LineClick, HoeWerktHet, GoedAangelegd, Borstzorg, Introduction, IntroductionText } from './styled';
import { Typography, SingleCarousel, Button, TitlePage, Image, Sticker, Video, TextToSpeech } from '../../components';

import Aanmelden from '../Ouders/Aanmelden';

import hoewerkthet from '../../assets/images/verloskundigen/hoewerkthet.jpg';
import verloskundigen from '../../assets/images/sticker-verloskundigen.png';

import background1 from '../../assets/backgrounds/lactatiekundigen1.png';
import background2 from '../../assets/backgrounds/lactatiekundigen2.png';

import brochure from '../../assets/brochure_verloskundige.pdf';

import BrochureModal from '../Lactatiekundigen/BrochureModal';
import { GeneralContext } from '../../App';

export default function Verloskundigen(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const introductionRef = useRef(null);
    const borstzorgRef = useRef(null);
    const goedAangelegdRef = useRef(null);
    const hoeWerktHetRef = useRef(null);
    const hoeWerktHetCRef = useRef(null);
    const benieuwdRef = useRef(null);
    const aanmeldenRef = useRef(null);

    const [ openBrochureModal, setOpenBrochureModal ] = useState(false);
    const [ openMailModal, setOpenMailModal ] = useState(false);
    const [ backgroundProps, setBackgroundProps ] = useState({});

    const scrollIntoView = (ref) => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        if (Object.keys(backgroundProps).length === 0 && introductionRef.current) {
            setBackgroundProps({
                top: introductionRef.current?.clientHeight,
                height: (borstzorgRef.current?.clientHeight + goedAangelegdRef.current?.clientHeight + hoeWerktHetRef.current?.clientHeight)*1.05,
            })
        }
    }, [backgroundProps]);
    
    return (
        <Container>
            <Sticker src={verloskundigen} position='fixed' top='80vh' width='10vw' hover={true} onClick={() => ( benieuwdRef.current && scrollIntoView(benieuwdRef) )} />
            {!hasPhone && <Background src={background1} zIndex='3' top='0' left='0' />}
            {!hasPhone && <Background src={background2} zIndex='1' top={backgroundProps.top + 'px'} right='0' height={backgroundProps.height + 'px'} />}
            <Introduction ref={introductionRef}>
                <div style={{ zIndex: '5'}}>
                    <Video videoId='my7Gzf2HW4c' width={'45vw'} />
                </div>
                <IntroductionText>
                    <Typography type='h1' style={{ color: '#114d55', margin: hasPhone ? '0 0 5vw 0' : '3vw 0 2vw 0',  width: hasPhone && '70vw', fontSize: 'clamp(18px, 2.2em, 46px)'}}>Kennis over borstvoeding <br/> werkt preventief <br/>voor problemen</Typography>
                    <TextToSpeech />
                </IntroductionText>
            </Introduction>
            <Borstzorg ref={borstzorgRef}>
                <Typography type='h1' style={{ color: '#b04f29', textAlign: 'center' }}>Borstzorg ondersteunen</Typography>
                <Typography style={{ textAlign: 'left' }}>Binnen de verloskunde ligt zowel de werk- als tijdsdruk hoog. De tijd nemen om aanstaande ouders goed te voor te bereiden op borstvoeding, schiet er dan vaak bij in. Hier komt bij dat de basisopleiding niet uitgebreid ingaat op de gespecialiseerde kennis en begeleiding rondom de lactatie. Hierdoor is borstvoeding in de verloskunde vaak een ondergeschoven kindje, terwijl het juist een essentieel onderdeel is van de gezondheid van moeder en kind. Goed Aangelegd wil hier in samenwerking met verloskundigen en lactatiekundigen verandering in brengen.</Typography>
            </Borstzorg>
            <GoedAangelegd ref={goedAangelegdRef}>
                <Typography type='h1' style={{ color: '#114d55' }}>Goed Aangelegd</Typography>
                <Typography>We hebben een allesomvattende workshopreeks ontwikkeld waarmee lactatiekundigen (aanstaande) ouders voorbereiden op hun borstvoedingsperiode. Onze workshops zijn gebaseerd op wetenschappelijk onderzoek en tot stand gekomen in samenwerking met meer dan 50 (zorg)professionals.
                    <br/><br/>In drie workshops voorziet de lactatiekundige de koppels van kennis en vaardigheden, die hem helpen de melkproductie op gang te krijgen, problemen te voorkomen, en de borstvoeding te combineren met het alledaagse leven.</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <Typography type='caps' style={{ color: '#145f68', fontSize: '1.2rem', textAlign: 'center' }}>Wat kan goed aangelegd voor{hasPhone && <br/>} jouw praktijk betekenen?</Typography>
                    <Typography>
                    Veel jonge ouders lopen tijdens de borstvoedingsperiode tegen tegenslagen en soms zelfs complicaties aan, die voorkomen hadden kunnen worden met de juiste voorbereiding. Goed Aangelegd haalt de verantwoordelijkheid voor die voorbereiding weg bij de verloskunde.
                    <br/>Ouders krijgen nog voordat hun kindje geboren is, via de Goed Aangelegd workshops, alle tools en informatie om succesvol borstvoeding te geven. Zo stappen koppels met vertrouwen en gedegen kennis, de voedingsperiode tegemoet en houden ze meer tijd over voor het leren kennen en genieten van hun baby.
                    <br/><br/>
                    In onze ogen een de ideale win-win situatie. De ouders hebben een extra positieve ervaring met jouw praktijk zonder dat jij er extra tijd in hoeft te steken. En jij houdt juist tijd over om andere belangrijke zorg te leveren, nu de verantwoordelijkheid voor de voorbereiding niet meer op jouw bord ligt.
                    <br/><br/>
                    Klinkt dit interessant?
                    </Typography>
                </div>
                <Button buttonstyle='secondary' text='Ja! Download de brochure' target='_blank' href={brochure} download='brochure-Goed-Aangelegd.pdf' />
            </GoedAangelegd>
            <HoeWerktHet ref={hoeWerktHetRef}>
                <TitlePage 
                    text='aanpak'
                    color='#be6942'
                />
                <Image borderColor='#be6942' objectFit='cover' src={hoewerkthet} height={hoeWerktHetCRef.current?.clientHeight} width={hasPhone ? '95rem' : '25vw'} />
                <div style={{ height: 'min-content' }} ref={hoeWerktHetCRef}>
                    <Typography type='h1' style={{ color: '#032d34' }}>Hoe werkt het?</Typography>
                    <Typography style={{ maxWidth: hasTablet ? '25rem' : '35rem', padding: !hasTablet && !hasPhone && '2rem 0', position: 'relative', zIndex: '3', marginTop: '1rem' }}>
                        Wij geloven dat jij een belangrijke sleutelrol hebt in de borstzorg. Je kunt ouders helpen door hen door te verwijzen naar een lokale lactatiekundige die Goed Aangelegd workshops geeft.<br/><br/>
                        Treed in samenwerking met de lactatiekundige(n) uit de omgeving, van wie je promotiemateriaal ontvangt. Informeer de koppels over het belang van voorbereiding en het bestaan van Centering Feeding. De aanmelding hiervoor verloopt via de lactatiekundige. De ouders kunnen ook bij de lactatiekundige terecht voor de datums en al hun andere vragen.
                    </Typography>
                    {/* <Button text='Bekijk hier de beschikbare materialen' buttonstyle='secondary' onClick={() => window.alert('Wat moet hier komen?')} /> */}
                </div>
            </HoeWerktHet>
            <Aanmelden 
                parentRef={aanmeldenRef} 
                category='verloskundige' 
                title='Deelnemende lactatiekundigen'
                downloadMailSjabloon={() => setOpenMailModal(true)}
            />
            <Samenwerken>
                <Typography type='h1' style={{ color: '#114d55' }}>Samenwerken</Typography>
                <Typography>
                    Dus wil jij impactvolle zorg geven aan je cliënten door voedingsproblemen een stap voor te zijn en hecht jij waarde aan wetenschappelijk onderbouwde aanpak? <br/>Word partner van Goed Aangelegd en help ouders kennismaken met onze workshops.
                </Typography>
                <Button text='Download de brochure' buttonstyle='sixthly' href={brochure} download='brochure-Goed-Aangelegd.pdf' target='_blank' />
                <br/><Typography type='caps' style={{ color: '#145f68', fontSize: '1em' }}>Eerst goed aangelegd beter leren kennen?</Typography>
                <Typography>
                    Plan direct een vrijblijvend persoonlijk gesprek in om verder kennis te maken met Goed Aangelegd en om al je vragen te stellen. Heb je de brochure nog niet ontvangen? <br/>
                    <br/>Vraag hem <LineClick target='_blank' href={brochure} download='brochure-Goed-Aangelegd.pdf'>hier</LineClick> aan.
                </Typography>
                <Button text='Ik wil dit gesprek!' buttonstyle='sixthly' href='https://calendly.com/goed-aangelegd/zorgprofessionals' />
            </Samenwerken>
            {false && <Ervaringen category='verloskundige' />}
            {false && <Veelgestelde category='verloskundige' />}
            {false && <Medewerking />}
            {false && <Educatie>
                {!hasPhone && <Background src={background1} rotateDeg='180' zIndex='1' height='160vw' top='-80%' left='-115%' />}
                <Typography type='h1' style={{ color: '#114d55', margin: hasPhone ? '0 0 7vw 0' : '0 0 2vw 0', width: '90%' }}>Borstvoedings{hasPhone && '-'}educatie</Typography>
                <Typography type='caps' style={{ color: '#145f68', fontSize: '1.4em', margin: hasPhone ? '5vw 0 3vw 0' : '0 0 1vw 0' }}>Aanbod Masterclasses</Typography>
                <Typography style={{ width: hasPhone ? '80%' : '45%'}}>
                    Als verloskundige ben je de eindverantwoordelijke in het kraambed en dus ook over het borstvoedingsbeleid. Een gedegen, praktische basiskennis is daarom van groot belang om het welslagen van de borstvoeding. Jij als verloskundige van de toekomst kan het verschil maken door de juiste kennis uit te dragen in het werkveld. De ‘Masterclasses Praktische Lactatiekunde’ zijn geïntegreerd in alle Academies Verloskunde binnen Nederland en België.
                    <br/><br/>Door de basisopleiding van de verloskundeacademies te voorzien van praktische kennis en begeleiding van de lactatie, wordt deze kennis meegedragen en verspreid in het werkveld. Ook dit draagt bij aan de missie om meer borstvoedingssuccessen te bereiken!
                </Typography>
                <Typography type='caps' style={{ color: '#145f68', fontSize: '1.4em', margin: hasPhone ? '10vw 0 3vw 0' : '2vw 0 0vw 0' }}>Ervaringen van deelnemers</Typography>
                <SingleCarousel category='Verloskundige in opleiding' />
                <Button 
                    buttonstyle='secondary'
                    to='/ervaringen'
                    text='Lees meer'
                    style={{ margin: hasPhone && '5vw 0 0 0'}}
                />
            </Educatie>}
            {false && <BlogEnPodcast category='verloskundige' />}
            <Benieuwd givenRef={benieuwdRef} category='verloskundige' />
            <BrochureModal 
                openModal={openBrochureModal}
                setOpenModal={setOpenBrochureModal}
                category='verloskundige' 
                title='Vraag brochure aan'
                buttonText='Aanvragen'
            />
            <BrochureModal 
                openModal={openMailModal}
                setOpenModal={setOpenMailModal}
                category='verloskundige' 
                title='Download de mail'
                buttonText='Verstuur'
            />
        </Container>
    );
}