import { Typography, Button } from "../../components"
import "./styles.css";

import klachtenregeling from '../../assets/documents/klachtenregeling.pdf';

export default function Klachtenregeling(props) {
    return (
        <div className='container'>
            <Typography type='h1' style={{ color: 'var(--trust)', fontSize: '2.2em' }}>Klachtenregeling</Typography>
            <div className="button-container">
                <Button 
                    target='_blank' 
                    href={klachtenregeling} 
                    text={`Download de klachtenregeling`}
                />
            </div>
        </div>
    )
}