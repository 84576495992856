import React, { useState } from 'react';

import { Vragen } from './styled';
import { Veelgestelde } from '../../subpages';
import { Button, Typography, ReactIcon, Accordion, ImageCarousel } from '../../components';
import AndersModal from './AndersModal';

import contact1 from '../../assets/images/contact/contact1.jpg';

import { GeneralContext } from '../../App';

const images = [contact1, contact1, contact1];

export default function Contact(props) {
    const { hasPhone } = React.useContext(GeneralContext);

    const [ openModal, setOpenModal ] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault()
    }
    
    return (
        <div>
            <Vragen>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: hasPhone && '90%' }}>
                    <Typography type='h1' style={{ color: '#032d34', margin: hasPhone ? '0 0 10vw 0' : '0 0 2vw 0' }}>Heb je nog vragen?</Typography>
                    <Typography style={{ margin: hasPhone ? '0 0 5vw 0' : '0 0 1vw 0', width: hasPhone && '90vw' }}>Zit jij nog met vragen, twijfels of wil je graag in gesprek {!hasPhone && <br/>}gaan met ons? Laat het dan weten!</Typography>
                    <Typography>Ik zoek contact als</Typography>
                    <div style={{ display: 'flex', gap: hasPhone ? '3vw' : '1vw', justifyContent: hasPhone && 'center', margin: hasPhone ? '3vw 0 10vw 0' : '0.5vw 0 2vw 0', flexWrap: 'wrap', width: hasPhone && '50vw'}}>
                        <Button text='Ouder' hashTo='/ouders#aanmelden' buttonstyle='fourthly' />
                        <Button text='Lactatiekundige' href='https://calendly.com/goed-aangelegd/zorgprofessionals' buttonstyle='fourthly' />
                        <Button text='Verloskundige' href='https://calendly.com/goed-aangelegd/zorgprofessionals' buttonstyle='fourthly' />
                        <Button text='Anders' href='mailto:goed.aangelegd@gmail.com' buttonstyle='fourthly' />
                    </div>
                    <div style={{ display: 'flex', gap: hasPhone ? '2vw' : '0.5vw', margin: hasPhone ? '0 0 7vw 0' : '0 0 1vw 0'}}>
                        <ReactIcon name='instagram' />
                        <ReactIcon name='linkedin' />
                        <ReactIcon name='youtube' />
                    </div>
                    <Accordion
                        headertext='Bedrijfsgegevens Goed Aangelegd'
                        headercolor='#145f68'
                        align='center'
                    >
                        <Typography>
                            Goed Aangelegd<br/>
                            Nadine Somers<br/>
                            Gravenkasteel 13,<br/>
                            6028 RK Gastel<br/><br/>

                            IBCLC: 309803<br/>
                            BIG:  39930368703<br/>
                            KvK: 75534487<br/>
                            BTW: NL002390299 B42<br/>
                            Bank: NL52 KNAB 0501 5935 35
                        </Typography>
                    </Accordion>
                </div>
                <ImageCarousel images={images} />
            </Vragen>
            {false && <Veelgestelde />}
            <AndersModal 
                open={openModal}
                onClose={() => setOpenModal(false)}
                onSubmit={onSubmit}
            />
        </div>
    );
}