import React from 'react';
import { Bar, Subbar, Rights } from './styled';
import Typography from '../Typography/Typography';
import { GeneralContext } from '../../App';

export default function BottomFooter(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    return (
        <Bar>
            <Rights>
                <Typography>GOED AANGELEGD | COPYRIGHT 2024, {hasPhone && <br/>}ALLE RECHTEN ZIJN VOORBEHOUDEN ©</Typography>
            </Rights>
        </Bar>
    )
}