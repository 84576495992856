import { Typography, Button } from "../../components"
import "./styles.css";

import cursist from '../../assets/documents/av_cursist.pdf';
import lactatiekundige from '../../assets/documents/av_lactatiekundige.pdf';

export default function AlgemeneVoorwaarden(props) {
    return (
        <div className='container'>
            <Typography type='h1' style={{ color: 'var(--trust)', fontSize: '2.2em' }}>Algemene voorwaarden</Typography>
            <div className="button-container">
                <Button 
                    target='_blank' 
                    href={cursist} 
                    text={`Voor cursisten`}
                />
                <Button 
                    target='_blank' 
                    href={lactatiekundige} 
                    text={`Voor lactatiekundigen`}
                />  
            </div>
        </div>
    )
}