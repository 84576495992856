import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    // display: flex;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Introduction = styled.div`
    display: flex;
    width: 100%;
    padding: 5vw 0vw;
    text-align: center;
    z-index: inherit;
    justify-content: center;
    gap: 1vw;
    // z-index: 3;
    // position: relative;
    background-color: #f7f3ec;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        flex-wrap: wrap;
        flex-direction: column-reverse; 
        align-items: center;
    }
`;

export const IntroductionText = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    align-items: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 100%;
        gap: 5vw;
        margin-bottom: 10vw;
    }
`;

export const Samenwerken = styled.div`
    background-color: white;
    padding: 3rem 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 3rem 5rem;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        padding: 5rem 20rem;
    }
`;

export const Background = styled.img`
    position: ${props => props.position || 'absolute'};
    z-index: ${props => props.zIndex || 1};

    width: ${props => props.width || 'default'};
    height: ${props => props.height || 'default'};

    top: ${props => props.top || 'default'};
    left: ${props => props.left || 'default'};
    bottom: ${props => props.bottom || 'default'};
    right: ${props => props.right || 'default'};

    margin: ${props => props.margin || '0'};

    transform: ${props => props.rotateDeg ? `rotate(${props.rotateDeg}deg)` : 'default'};
`;

export const WatIs = styled.div`
    background-color: #f7f3ec;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    gap: 1em;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 3rem 5rem;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        padding: 5rem 20rem;
    }
`;

export const Workshops = styled.div`
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem;
    background-color: #fdfcfa;
    position: relative;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 3rem 4rem;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        padding: 5rem 10rem;
    }
`;

export const WorkshopsText = styled.div`
    width: 100%;

    /* Tablet: height */
    @media (min-width: 641px)  {
        max-width: 60vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        max-width: 60vw;
    }
`;

export const WorkshopsContent = styled.div`
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    gap: 2vw;
    margin: 7vw 0;

    /* Tablet: height */
    @media (min-width: 641px)  {
        flex-direction: row;
        gap: 5vw;
        margin: 2rem 0;
    }
`;

export const WorkshopImages = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;

    /* Tablet: height */
    @media (min-width: 641px)  {
        justify-content: flex-start;
        gap: 2rem;
        flex-direction: column;
    }
`;

export const WorkshopImage = styled.img`
    width: 25vw;

    /* Tablet: height */
    @media (min-width: 641px)  {
        width: 15vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        width: 8vw;
    }
`;

export const Kosten = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f6efe7;
    padding: 3rem 2rem;
    z-index: 4;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 3rem 5rem;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        padding: 5rem 20rem;
    }
`;

export const Kracht = styled.div`
    position: relative;
    padding: 3rem 2rem;

    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 3em;

    background-color: #fdfcfa;

    /* Tablet: height */
    @media (min-width: 641px)  {
       flex-direction: row;
       gap: 3em;
       padding: 3rem 5rem;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        padding: 5rem 10rem;
    }
`;

export const KrachtText = styled.div`
    width: 100%;
`;

export const Enthousiast = styled.div`
    padding: 3rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    background-color: #f1e3d4;

    /* Tablet: height */
    @media (min-width: 641px)  {
       padding: 3rem 5rem;
       gap: 3rem;
    }
`;

export const EnthousiastCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;

    @media (min-width: 1100px)  {
       max-width: 40vw;
    }
`;

export const ModalBox = styled.div`
    position: absolute;
    left: 40%;
    top: 30%;
    width: 24vw;
    height: 24vw;
    background-color: white;
    box-shadow: 24;

    padding: 1vw;
    display: flex;
    flex-direction: column;

    border: solid 1px white;
    border-radius: 1%;
`