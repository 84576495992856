import React, { useState } from 'react';
import Typography from '../Typography/Typography';
import { Header, Arrow } from './styled';
import { GeneralContext } from '../../App';

export default function Accordion(props) {
    const { align, width } = props;

    const [ isOpen, setIsOpen ] = useState(false);

    const { hasPhone } = React.useContext(GeneralContext);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: align || 'flex-start', height: 'min-content', width: width || 'default'}} {...props}>
            <Header
                onClick={() => { setIsOpen(!isOpen) }}
                color={props.headercolor}
                style={props.headerStyle}
                // style={{ width: '200px'}}
            >
                <Arrow shouldRotate={isOpen}>{'>'}</Arrow>
                <Typography type='caps'>{props.headertext}</Typography>
            </Header>
            {isOpen && 
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: align || 'flex-start', width: '100%'}}>
                    <div style={{ marginBottom: hasPhone ? '5vw' : '0vw' }}>{props.children}</div>
                    <Header
                        onClick={() => { setIsOpen(!isOpen) }}
                        color={props.headercolor}
                    >
                        <Typography type='caps' style={{ marginTop: '1vw' }}>Menu inklappen</Typography>
                    </Header>
                </div>
            }
        </div>
    )
}