import React from 'react';
import { Container } from './styled';

import { TitlePage, Typography } from '../../components';

import oprichtster from '../../assets/images/medewerking/Oprichtster.png';
import onderzoek from '../../assets/images/medewerking/Onderzoek.png';
import professionals from '../../assets/images/medewerking/Professionals.png';
import { GeneralContext } from '../../App';

const medewerkingList = [
    { title: 'Oprichtster\n Goed Aangelegd', image: oprichtster, text: 'Nadine Somers is de drijvende kracht van Goed Aangelegd. "Als verloskundige en lactatiekundige miste ik goede borstvoedingszorg tijdens de zwangerschap. Door koppels de juiste voorbereiding te bieden, geven méér vrouwen langer borstvoeding. Daarom hebben mijn team en ik borstvoedingsworkshops opgezet. Hiermee ondersteunen we zowel ouders als zorgverleners bij het borstvoedingsavontuur. Onze gezamenlijke missie: een fijne en succesvolle borstvoedingsperiode voor iedereen die dat wil!"' },
    { title: 'Meer dan 50 professionals', image: professionals, text: 'In samenwerking met onderwijskundigen, grafisch vormgevers en meer dan 50 andere (zorg)professionals hebben we de borstvoedingsworkshops ontwikkeld. Dankzij deze diverse groep experts hebben we een stevig en kwalitatief fundament voor onze dienstverlening kunnen creëren. De zorgprofessionals hebben de workshops voorzien van de meest recente adviezen binnen hun vakgebied. Ons team van designers heeft de dienstverlening in een moderne, universele en ecologische vorm gegoten. Zo is het toegankelijk voor iedereen!' },
    { title: 'Wetenschappelijk onderzoek', image: onderzoek, text: 'Goed Aangelegd is gebaseerd op wetenschappelijk onderzoek. Uit onderzoek blijkt dat (aanstaande) ouders in elke fase van de zwangerschap behoefte hebben aan specifieke informatie over borstvoeding. Deze inzichten vormen de structuur van de workshops. De aanpak van de dienstverlening is eveneens gebaseerd op onderzoek, waarbij interactieve en gevarieerde benaderingen als meest effectief naar voren komen. De dienstverlening wordt continu geëvalueerd om de kwaliteit en effectiviteit te verbeteren.' },
]

export default function Mogelijk(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    let imgSize = 200;
    let paddingSize = 35;

    if (hasPhone) {
        imgSize = 200;
        paddingSize = 10;
    } else if (hasTablet) {
        imgSize = 160;
        paddingSize = 15;
    }

    return (
        <Container>
            <TitlePage 
                text='MOGELIJK GEMAAKT DOOR'
                color='#145f68'
            />
            <Typography type='h2' style={{ color: '#145f68', fontSize: '2em', margin: hasPhone ? '0 1vw' : '0 0 1vw 0' }}>Mogelijk gemaakt door</Typography>
            <div style={{ display: 'flex', justifyContent: 'center', gap: hasPhone ? '10vw' : '5vw', flexWrap: 'wrap'}}>
                {medewerkingList.map((m, i) => (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', width: `${imgSize+paddingSize}px`, backgroundColor: !(hasPhone || hasTablet) && 'white' }} key={i}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img alt={m.title} src={m.image} style={{ width: `${imgSize}px`, height: `${imgSize}px`, border: '1vw solid white ' }} />
                            <Typography type='h2' style={{ fontSize: '1.5em', padding: '0.5vw 1vw 0 1vw', textAlign: 'center', color: '#145f68', marginBottom: '0.5vw', whiteSpace: 'pre-line'}}>{m.title}</Typography>
                        </div>
                        {!(hasPhone || hasTablet) && <div style={{ height: 'min-content', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', gap: hasPhone && '3vw', padding: hasPhone ? '7vw' : `${paddingSize/2}px`}}>
                            <Typography style={{ textAlign: 'left', padding: hasPhone ? '3vw' : '1vw', fontSize: '14px' }}>
                                {m.text}
                            </Typography>
                        </div>}
                    </div>
                ))}
            </div>
        </Container>
    )
}