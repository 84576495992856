import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    width: 100%;
`;

export const Introduction = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 2rem 2rem;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 3rem 5rem;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        gap: 0.5em;
        padding: 2rem 20rem;
    }
`;

export const Tijdsinvestering = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    gap: 1em;

    background-color: #FDFCFA;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 3rem 5rem;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        padding: 3rem 20rem;
    }
`;

export const SliderMark = styled.img`
    width: 1em;
`;

export const Winst = styled.div`
    background-color: #F8F3EC;
    padding: 3rem 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 3rem 5rem;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        padding: 3rem 20rem;
    }
`;