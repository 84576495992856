import styled from "styled-components";

export const Container = styled.div`
    background-color: #e9f1ed;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    padding: 3rem 2rem;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 3rem 5rem;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        padding: 5rem 10rem;
    }
`;