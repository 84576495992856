import styled from "styled-components";

export const Container = styled.div`
    background-color: #fdfcfa;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    position: relative;
    gap: 1vw;
    text-align: center;

    /* Tablet: height */
    @media (min-width: 641px)  {
       padding: 3rem 5rem;
    }
`;


export const Background = styled.img`
    position: ${props => props.position || 'absolute'};
    z-index: ${props => props.zIndex || 1};

    width: ${props => props.width || 'default'};
    height: ${props => props.height || 'default'};

    top: ${props => props.top || 'default'};
    left: ${props => props.left || 'default'};
    bottom: ${props => props.bottom || 'default'};
    right: ${props => props.right || 'default'};

    margin: ${props => props.margin || '0'};

    transform: ${props => props.rotateDeg ? `rotate(${props.rotateDeg}deg)` : 'default'};
`;