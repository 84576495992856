import React, {useState, useEffect, useRef } from 'react';
import * as Papa from 'papaparse';

import { SingleContainer, SingleBox, SingleArrow } from './styled';
import { mod } from '../../helpers/others';
import SingleCarouselComponent from './SingleCarouselComponent';
import { GeneralContext } from '../../App';

const loadImages = require.context('../../assets/images/reviews', true);

export default function SingleCarousel(props) {
    const { category } = props;

    const { hasPhone } = React.useContext(GeneralContext);

    const [ index, setIndex ] = useState(0);
    const [ transitioning, setTransition ] = useState(false);
    const timeoutId = useRef(null);

    const [ reviews, setReviews ]  = useState([]);
    
    const duration = 8000;

    const parseReviewCsv = (data, columns) => {
        let dataRows = data.data;

        let newReviews = []
        for (let i = 1; i < dataRows.length; i++) {
            let row = dataRows[i];
            let dataObj = {}
            for (let j = 0; j < row.length; j++) {
                if (columns[j] === 'foto-name') {
                    // fetch photo based on name and store in object
                    dataObj['image'] = loadImages(`./${row[j]}`)
                } else {
                    dataObj[columns[j]] = row[j];
                }
            }
            newReviews.push(dataObj);
        }

        // filter on category if given
        if (category && category.length > 0) {
            newReviews = newReviews.filter(r => r.category === category);
        }

        // shuffle
        newReviews.sort((a,b) => 0.5 - Math.random());

        setReviews(newReviews);
    }

    useEffect(() => {
        if (!timeoutId.current && reviews.length > 1) {
            setTimeout(() => ( setTransition(true) ), duration - 300);
            const timeout = setTimeout(() => ( setIndex(mod(index + 1, reviews.length)), timeoutId.current = null ), duration);
            timeoutId.current = timeout;
            setTimeout(() => transitioning ? setTransition(false) : null, 300);
        }

        if (reviews.length === 0) {
            fetch( './reviews.csv' )
                .then( response => response.text() )
                .then( responseText => {
                    const data = Papa.parse(responseText);
                    
                    const columns = data['data'][0];
                    parseReviewCsv(data, columns)
                })
        }
    }, [index, reviews.length, transitioning]);

    if (reviews.length > 0) {
        return (
            <SingleContainer>
                {!hasPhone && <SingleArrow
                    onClick={() => { setIndex(mod(index - 1, reviews.length)); timeoutId.current = null; setTransition(false); }}
                >
                    {'<'}
                </SingleArrow>}
                <SingleBox>
                    <SingleCarouselComponent review={reviews[index]} transitioning={transitioning} category={category} />
                </SingleBox>
                {!hasPhone && <SingleArrow
                    onClick={() => { setIndex(mod(index + 1, reviews.length)); timeoutId.current = null; setTransition(false); }}
                >
                    {'>'}
                </SingleArrow>}
            </SingleContainer>
        )
    } 
}