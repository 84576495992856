import React, { useEffect, useState } from 'react';

import { TitlePage, Typography, TripleCarousel, Button } from '../../components';
import { Container } from './styled';
import { GeneralContext } from '../../App';

import * as Papa from 'papaparse';
import { titleCase } from '../../helpers/others';

const loadImages = require.context('../../assets/images/medewerking', true);

export default function Medewerking(props) {
    const { hasPhone } = React.useContext(GeneralContext);

    const [ partners, setPartners ] = useState([]);

    const parseMedewerkingCsv = (data, columns) => {
      let dataRows = data.data;

      const lowerColumns = columns.map(c => c.toLowerCase());

      let newPartners = []
      for (let i = 1; i < dataRows.length; i++) {
          let row = dataRows[i];
          let dataObj = {}
          for (let j = 0; j < row.length; j++) {
              dataObj[lowerColumns[j]] = row[j];
          }

          let photoName = '';
          if (dataObj['foto'] === 'ja') {
            photoName = titleCase(dataObj['voornaam']);
            let lastNameLst = dataObj['achternaam'].split(' ');
            for (let j = 0; j < lastNameLst.length; j++) {
              photoName += titleCase(lastNameLst[j]);
            }
          } else {
            photoName = 'ZonderProfielfoto'
          }
            
          photoName += '.extensie.jpg';
          dataObj['image'] = loadImages(`./${photoName}`);

          newPartners.push(dataObj);
      }

      // shuffle
      newPartners.sort((a,b) => 0.5 - Math.random());

      setPartners(newPartners);
  }

    useEffect(() => {
      if (partners.length === 0) {
          fetch( './medewerking.csv' )
              .then( response => response.text() )
              .then( responseText => {
                  const data = Papa.parse(responseText);
                  
                  const columns = data['data'][0];
                  parseMedewerkingCsv(data, columns)
              })
      }
  }, [partners]);

  if (partners.length > 0) {
    return (
        <Container>
            <TitlePage 
                text='MET MEDEWERKING VAN'
                color='#145f68'
            />
            <Typography type='h2' style={{ color: '#145f68', fontSize: hasPhone ? '1.8em' : '2.5em', width: hasPhone ? '90%' : '100%', textAlign: 'center' }}>Met medewerking van</Typography> 
            <TripleCarousel itemList={partners} imagePath='medewerking' contentType='medewerking' />
            <Button 
                buttonstyle='secondary'
                to='/medewerking'
                text='Bekijk meer'
            />          
        </Container>
    )
  }
}